<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 100px;">
                                申购单编号：
                            </td>
                            <td>
                                <el-input v-model="searchItem.applyNum" size="small" placeholder="请输入申购单编号"
                                ></el-input>
                            </td>
                            <td style="width: 80px">
                                申购仓库：
                            </td>
                            <td>
                                <el-select v-model="searchItem.warehouseType" style="width: 100%" size="small"
                                           placeholder="请选择申购仓库">
                                    <el-option
                                            v-for="item in depotTypes"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 80px">
                                申购时间：
                            </td>
                            <td width="28%">
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width: 46.5%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date" placeholder="选择日期时间"
                                        size="small"
                                        style="width: 47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </td>
                            <td style="width: 300px ;text-align: center">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                </el-button>
                                <el-button type="primary" icon="el-icon-circle-plus" size="small"
                                           v-if=" hasAuthority('material_purchase_add')" @click="addObject">
                                    增加
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                            <el-table-column prop="applyNum" width="180" label="申购单编号" align="center"></el-table-column>
                            <el-table-column prop="applyType" width="160" label="申购类型" align="center">
                                <template slot-scope='scope'>
                                    <span v-if="scope.row.applyType == 0">研发申购</span>
                                    <span v-else-if="scope.row.applyType ==1">生产申购</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" width="240" label="申购时间" :formatter="dateFormat"
                                             align="center"></el-table-column>
                            <el-table-column prop="createUserName" width="160" label="申购人"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="warehouseType" width="180" label="申购仓库" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope='scope'>
                                    <span v-if="scope.row.warehouseType == 0">主物料仓库</span>
                                    <span v-else-if="scope.row.warehouseType ==1">研发物料仓库</span>
                                    <span v-else-if="scope.row.warehouseType ==2">外协仓库</span>
                                    <span v-else-if="scope.row.warehouseType ==3">售后仓库</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="remark" label="备注"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="auditState" width="120" label="状态" align="center">
                                <template slot-scope='scope'>
                                    <span v-if="scope.row.isFinished == 1" style="color: green">已采购</span>
                                    <span v-else-if="scope.row.auditState == 0">草稿</span>
                                    <span v-else-if="scope.row.auditState ==10" style="color: green">已通过</span>
                                    <span v-else-if="scope.row.auditState ==-1" style="color: red">已驳回</span>
                                    <span v-else style="color: #20a0ff">审批中</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="id" width="150" label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconbianji" title="编辑"
                                           v-if="scope.row.auditState == 0 && hasAuthority('material_purchase_update')"
                                           style="margin-right: 10%" @click="editMaterialPurchase(scope.row.id)"></i>
                                        <i class="iconfont iconxiangqing"
                                           title="详情" @click="showMaterialPurchase(scope.row.id)"></i>
                                        <i class="iconfont icontongguo" style="margin-left: 10%" title="申购"
                                           v-if="scope.row.auditState == 0 && hasAuthority('material_purchase_apply')"
                                           @click="submitMaterialPurchase(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog-form">
            <el-dialog
                    :title="editTitle"
                    :visible.sync="editDialogVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <el-form :model="purchaseForm" :rules="purchaseRules" ref="purchaseForm" label-width="100px"
                         class="demo-ruleForm">
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="申购类型:" prop="applyType">
                                <el-select v-model="purchaseForm.applyType" style="width: 100%" size="small"
                                           placeholder="请选择申购类型">
                                    <el-option
                                            v-for="item in purchaseTypes"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="申购仓库:" prop="warehouseType">
                                <el-select v-model="purchaseForm.warehouseType"
                                           style="width: 100%"
                                           size="small"
                                           @change="getMaterialList"
                                           placeholder="请选择申购仓库">
                                    <el-option
                                            v-for="item in depotTypes"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item size="small" label="备注:" style="width: 100%;" prop="name">
                        <el-input type="textarea" v-model="purchaseForm.remark" rows="2"></el-input>
                    </el-form-item>
                </el-form>

                <div class="table-material">
                    <div>
                        <table width="100%">
                            <tr>
                                <td style="width: 100px;">
                                    物料编码：
                                </td>
                                <td>
                                    <el-input v-model="searchBasicItem.materialCode" size="small" placeholder="请输入物料编码"
                                    ></el-input>
                                </td>
                                <td style="width: 80px">
                                    申购部件：
                                </td>
                                <td>
                                    <el-select v-model="searchBasicItem.component" style="width: 100%" size="small"
                                               filterable placeholder="请选择申购部件">
                                        <el-option
                                                v-for="(item,index) in componentList"
                                                :key="index"
                                                :label="item.componentName"
                                                :value="item.component">
                                        </el-option>
                                    </el-select>
                                </td>
                                <td style="text-align: center;width: 200px">
                                    <el-button type="primary" icon="el-icon-search" size="small"
                                               @click="searchBasicBtn">搜索
                                    </el-button>
                                    <el-button type="primary" icon="el-icon-refresh" size="small"
                                               @click="resetBasicBtn">重置
                                    </el-button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="material-table">
                        <template>
                            <el-table
                                    ref="multipleTable"
                                    :data="tableList"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                    max-height="350"
                                    border
                                    :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                    :cell-style="{padding:'0',height: lineHeight}"
                                    @selection-change="handleSelectionChange"
                            >
                                <el-table-column type="selection" width="40" align="center"></el-table-column>
                                <el-table-column prop="materialCode" label="物料编码" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="materialName" label="产品名称" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="model" label="型号" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="count" label="数量" align="center" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <el-input type="tel" size="small" v-if="scope.row.checked == false"
                                                  v-model.number="scope.row.count"
                                                  onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                                  placeholder="请输入数量" disabled></el-input>
                                        <el-input type="tel" size="small" v-else v-model.number="scope.row.count"
                                                  onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                                  placeholder="请输入数量"></el-input>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel('purchaseForm')">取 消</el-button>
                    <el-button type="primary" @click="submit('purchaseForm')" :loading="isLoad">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="dialog-form">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoDialogVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <div class="basic-table">
                    <table width="100%">
                        <tr>
                            <td width="100px" style="text-align: left">申购单编号：</td>
                            <td style="text-align: left">{{purchaseDisplay.applyNum}}</td>
                            <td width="100px" style="text-align: left">申购人：</td>
                            <td style="text-align: left">{{purchaseDisplay.createUserName}}</td>
                            <td width="100px" style="text-align: left">申购类型：</td>
                            <td style="text-align: left">{{purchaseDisplay.applyType == 0?'研发申购':'生产申购'}}</td>
                        </tr>
                        <tr>
                            <td width="100px" style="text-align: left;padding-top: 10px">申购时间：</td>
                            <td style="text-align: left;padding-top: 10px">
                                {{changeDateFormat(purchaseDisplay.createTime)}}
                            </td>
                            <td width="100px" style="text-align: left;padding-top: 10px">申购仓库：</td>
                            <td style="text-align: left;padding-top: 10px">
                                {{purchaseDisplay.warehouseType == 0?"主物料仓库":
                                (purchaseDisplay.warehouseType == 1?"研发物料仓库" :
                                purchaseDisplay.warehouseType == 2?"外协仓库":'售后仓库')}}
                            </td>
                        </tr>
                        <tr>
                            <td width="100px" style="text-align: left;padding-top: 10px">备注：</td>
                            <td style="text-align: left;padding-top: 10px" colspan="5">{{purchaseDisplay.remark}}</td>
                        </tr>
                        <tr>
                            <td style="text-align: left;padding-top: 10px" colspan="6">
                                <div class="table-list">
                                    <el-table
                                            :data="tableShowList"
                                            tooltip-effect="dark"
                                            style="width: 100%"
                                            max-height="500"
                                            border
                                            :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                            :cell-style="{padding:'0',height: lineHeight}"
                                    >
                                        <el-table-column type="index" label="序号" width="50" align="center"
                                                         show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="materialCode" label="物料编码" align="center"
                                                         show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="materialName" label="产品名称" align="center"
                                                         show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="model" label="型号" align="center"
                                                         show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="count" label="数量" align="center"
                                                         show-overflow-tooltip></el-table-column>
                                    </el-table>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td width="100px" style="text-align: left;padding-top: 10px">审批意见：</td>
                            <td style="text-align: left;padding-top: 10px" colspan="5">{{purchaseDisplay.auditRemark}}
                            </td>
                        </tr>
                    </table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoDialogVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageList: [15, 20, 30, 50],
                tableHeight: '',
                tableData: [],
                tableList: [],
                noCheckedList: [],
                checkList: [],
                multipleSelection: [],
                componentList: [],
                tableShowList: [],
                selectData: [],
                option: {
                    pageSize: 15,
                    pageIndex: 1,
                },
                totalPage: 10,
                editDialogVisible: false,
                infoDialogVisible: false,
                isLoad: false,
                lineHeight: '',
                editTitle: '',
                type: '',
                showTitle: '',
                searchItem: {
                    applyNum: '',
                    warehouseType: '',
                    startDate: '',
                    endDate: '',
                    pageSize: 15,
                    pageIndex: 1,
                },
                purchaseForm: {
                    id: null,
                    applyType: '',
                    warehouseType: '',
                    remark: '',
                    applyDetailStr: ''
                },
                purchaseDisplay: {
                    applyType: '',
                    applyNum: '',
                    warehouseType: '',
                    createTime: '',
                    createUserName: '',
                    remark: '',
                    auditRemark: '',
                    applyDetailStr: ''
                },
                purchaseTypes: [
                    {
                        value: 0,
                        label: '研发申购'
                    },
                    {
                        value: 1,
                        label: '生产申购'
                    }
                ],
                depotTypes: [
                    {
                        value: 0,
                        label: '主物料仓库'
                    },
                    {
                        value: 1,
                        label: '研发物料仓库'
                    },
                    {
                        value: 2,
                        label: '外协仓库'
                    },
                    {
                        value: 3,
                        label: '售后仓库'
                    }
                ],
                checked: false,
                purchaseRules: {
                    applyType: [{required: true, message: '请选择申购类型', trigger: 'blur'}],
                    warehouseType: [{required: true, message: '请选择申购仓库', trigger: 'blur'}],
                },
                searchBasicItem: {
                    materialCode: '',
                    component: ''
                }
            };
        },
        methods: {
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.$api.getApplyMaterial(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.getApplyMaterial(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElments;
                    }
                })
            },
            reset() {
                this.searchItem = {
                    applyNum: '',
                    warehouseType: '',
                    startDate: '',
                    endDate: '',
                    pageSize: 15,
                    pageIndex: 1,
                }
                this.pageChange(1);
            },
            searchBtn() {
                this.pageChange(1);
            },
            addObject() {
                this.isLoad = false;
                this.editDialogVisible = true;
                this.editTitle = '新增申购单信息';
                this.type = 0;
                this.tableList = [];
                this.noCheckedList = [];
                this.componentList = [];
                this.checkList = [];
                this.searchBasicItem = {
                    materialCode: '',
                    component: ''
                };
                this.purchaseForm = {
                    id: null,
                    applyType: '',
                    warehouseType: '',
                    remark: '',
                    applyDetailStr: ''
                };
            },
            cancel(formName) {
                this.$refs[formName].resetFields();
                this.editDialogVisible = false;
            },
            submit(formName) {
                this.isLoad = true;
                var newArr = [];
                var checkListStr = '';
                for (const item of this.tableList) {
                    if (item.checked) {
                        newArr.push(item)
                    } else {
                        continue;
                    }
                }
                for (var item of newArr) {
                    if (item.count == '' || item.count == 0 || item.count == undefined) {
                        this.$message.error('所选物料的申购数量不能为空或者为0');
                        this.isLoad = false;
                        return;
                    } else {
                        if (String(item.count).split('.').length > 1) {
                            this.$message.error('所选物料的申购数量不能为小数');
                            this.isLoad = false;
                            return;
                        }
                    }
                }
                for (var item of newArr) {
                    if (checkListStr == '' || checkListStr == undefined) {
                        checkListStr = item.id + ',' + item.count;
                    } else {
                        checkListStr = checkListStr + '|' + item.id + ',' + item.count;
                    }
                }
                if (checkListStr == '' || checkListStr == undefined) {
                    this.$message.error('请选择要采购的物料信息');
                    this.isLoad = false;
                    return;
                } else {
                    this.purchaseForm.applyDetailStr = checkListStr;
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.type == 0) {
                            this.$api.addApplyMaterial(this.purchaseForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange("refresh");
                                    this.editDialogVisible = false;
                                    this.isLoad = false;
                                    this.$message.success(res.message);
                                } else {
                                    this.isLoad = false;
                                    this.$message.error(res.message);
                                }
                            })
                        } else {
                            this.$api.updateApplyMaterial(this.purchaseForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange("refresh");
                                    this.editDialogVisible = false;
                                    this.isLoad = false;
                                    this.$message.success(res.message);
                                } else {
                                    this.isLoad = false;
                                    this.$message.error(res.message);
                                }
                            })
                        }
                    } else {
                        this.isLoad = false;
                        return false;
                    }
                });
            },
            submitMaterialPurchase(id) {
                this.$confirm('你确定要提交该申购单信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.submitApplyMaterial({id: id}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh");
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                })
            },
            dateFormat(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            changeDateFormat(data) {
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            editMaterialPurchase(id) {
                this.isLoad = false;
                this.editDialogVisible = true;
                this.type = 1;
                this.$api.getApplyMaterialById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.editTitle = '编辑《' + res.data.applyNum + '》申购信息';
                        this.purchaseForm = {
                            id: res.data.id,
                            warehouseType: res.data.warehouseType,
                            remark: res.data.remark,
                            applyType: res.data.applyType
                        }
                        for (const item of res.data.materialList) {
                            item.checked = true;
                        }
                        this.checkList = res.data.materialList;
                        this.getEditMaterialList(res.data.warehouseType);
                    }
                })
            },
            showMaterialPurchase(id) {
                this.infoDialogVisible = true;
                this.showTitle = '《' + '》采购单详情'
                this.$api.getApplyMaterialById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.showTitle = '查看《' + res.data.applyNum + '》申购信息详情';
                        this.purchaseDisplay = {
                            applyNum: res.data.applyNum,
                            warehouseType: res.data.warehouseType,
                            createTime: res.data.createTime,
                            createUserName: res.data.createUserName,
                            auditRemark: res.data.auditRemark,
                            remark: res.data.remark,
                            applyType: res.data.applyType
                        }
                        this.tableShowList = res.data.materialList;
                    }
                })

            },
            toggleSelection(rows) {
                if (rows) {
                    this.$nextTick(function () {
                        for (var row of rows) {
                            this.$refs.multipleTable.toggleRowSelection(row, true)
                        }
                    })
                }
            },
            handleSelectionChange(val) {
                var flag = false;
                if (this.uniqueId(this.multipleSelection).length < val.length) {
                    flag = true;
                }
                if (flag) {
                    for (var item of this.tableList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    for (var item of this.tableList) {
                        item.checked = false;
                    }
                    for (var item of this.tableList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                }
                this.multipleSelection = val
            },
            searchBasicBtn() {
                var newArr = [];
                for (const item of this.tableList) {
                    if (item.checked == true) {
                        newArr.push(item)
                    } else {
                        this.noCheckedList.push(item);
                    }
                }
                this.noCheckedList = this.uniqueId(this.noCheckedList)
                for (var item of this.noCheckedList) {
                    if (this.searchBasicItem.materialCode != '' && this.searchBasicItem.materialCode != undefined
                        && this.searchBasicItem.component != '' && this.searchBasicItem.component != undefined) {
                        if (item.component.indexOf(this.searchBasicItem.component) > -1) {
                            if (item.materialCode.indexOf(this.searchBasicItem.materialCode) > -1) {
                                newArr.push(item)
                            }
                        }
                    } else if (this.searchBasicItem.materialCode != '' && this.searchBasicItem.materialCode != undefined) {
                        if (item.materialCode.indexOf(this.searchBasicItem.materialCode) > -1) {
                            newArr.push(item)
                        }
                    } else if (this.searchBasicItem.component != '' && this.searchBasicItem.component != undefined) {
                        if (item.component.indexOf(this.searchBasicItem.component) > -1) {
                            newArr.push(item)
                        }
                    }
                }
                this.tableList = this.uniqueId(newArr);
                var checkedArr = []
                for (const item of this.uniqueId(newArr)) {
                    if (item.checked) {
                        checkedArr.push(item)
                    }
                }
                this.toggleSelection(checkedArr)
            },
            resetBasicBtn() {
                this.searchBasicItem = {
                    materialCode: '',
                    component: '',
                }
                for (var item of this.selectData) {
                    this.tableList.push(item);
                }
                this.tableList = this.uniqueId(this.tableList)
                var checkedArr = []
                for (const item of this.uniqueId(this.tableList)) {
                    if (item.checked) {
                        checkedArr.push(item)
                    }
                }
                this.toggleSelection(checkedArr)
            },
            getMaterialList(val) {
                this.componentList = []
                this.checkList = []
                this.$api.getMaterialList({warehouseType: val}).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data) {
                            item.checked = false;
                        }
                        this.tableList = res.data;
                        this.selectData = res.data;
                        for (const item of this.checkList) {
                            if (item.checked == true) {
                                this.tableList.unshift(item)
                            }
                        }
                        this.tableList = this.uniqueId(this.tableList)
                        for (const item1 of res.data) {
                            var item = {}
                            item.component = item1.component;
                            item.componentName = item1.componentName;
                            this.componentList.push(item);
                        }
                        this.componentList = this.unique(this.componentList)
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            getEditMaterialList(val) {
                this.componentList = []
                this.$api.getMaterialList({warehouseType: val}).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data) {
                            item.checked = false;
                        }
                        this.tableList = res.data;
                        this.selectData = res.data;
                        for (const item of this.checkList) {
                            if (item.checked == true) {
                                this.tableList.unshift(item)
                            }
                        }
                        this.tableList = this.uniqueId(this.tableList)
                        var newArr = []
                        for (var re of this.tableList) {
                            if (re.checked) {
                                newArr.push(re)
                            }
                        }
                        this.toggleSelection(newArr)
                        for (const item1 of res.data) {
                            var item = {}
                            item.component = item1.component;
                            item.componentName = item1.componentName;
                            this.componentList.push(item);
                        }
                        this.componentList = this.unique(this.componentList)
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            unique(arr) {
                var newArr = [];
                var obj = [];
                for (var i = 0; i < arr.length; i++) {
                    if (!obj[arr[i].component]) {
                        newArr.push(arr[i]);
                        obj[arr[i].component] = true;
                    }
                }
                return newArr;
            },
            uniqueId(arr) {
                var newArr = [];
                var obj = [];
                for (var i = 0; i < arr.length; i++) {
                    if (!obj[arr[i].id]) {
                        newArr.push(arr[i]);
                        obj[arr[i].id] = true;
                    }
                }
                return newArr;
            }
        },
        created() {
            this.pageChange(1);
            this.tableHeight = (window.innerHeight) * 0.76
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
        ,
    }
    ;
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .iconbianji {
        color: #20a0ff;
    }

    .icontongguo {
        color: green;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 6%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    td {
        text-align: right;
    }

    .material-table {
        margin-top: 10px;
    }

    .basic-table {
        margin-bottom: 10px;
    }

    td {
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

</style>